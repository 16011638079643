import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {FaqItem, FaqService} from '../../shared/services/faq.service';
import {take} from 'rxjs/operators';
import {SeoService} from '../../shared/services/seo.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LocationsResponse} from '../../map/map.component';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';

@Component({
  selector: 'app-mbo-category',
  templateUrl: './mbo-category.component.html',
  styleUrls: ['./mbo-category.component.scss']
})
export class MboCategoryComponent implements OnInit, AfterViewInit {
  faqs: FaqItem[] | undefined;

  selectedValues = new Set();
  domains?: {id: number | string; name: string}[];

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;

  constructor(
    private faqService: FaqService,
    private seo: SeoService,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.seo.updateTags(
        'Scholenwijzer Den Haag',
        'De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.'
    );

    this.http.get<LocationsResponse>(environment.api + '/locations/mbo')
      .subscribe(locationInfo => {
        this.domains = locationInfo.meta
          .find(filter => filter.field === 'domains')
          ?.options;

        setTimeout(() => {
          // @ts-expect-error jerome stuff
          initJeromeJs();
        }, 0);
      });

    this.faqService.getFaqItemsForCategory('MBO').pipe(take(1)).subscribe(faqs => {
      this.faqs = faqs;

      setTimeout(() => {
        // @ts-expect-error jerome stuff
        initJeromeJs();
      }, 0);
    });
  }

  change(event: Event): void {
    // @ts-expect-error event.target should have property checked
    const checked = event.target.checked;
    // @ts-expect-error event.target should have property value
    const values = event.target.value.split(',');

    if (checked) {
      values.forEach(v => this.selectedValues.add(v));
    } else {
      values.forEach(v => this.selectedValues.delete(v));
    }
  }

  getQueryParams() {
    const params: {domains?: string} = {};
    if (this.selectedValues.size > 0) {
      params.domains = [...this.selectedValues].join(',');
    }
    return params;
  }

    ngAfterViewInit() {
        this.animation = lottie.loadAnimation({
            container: document.querySelector('ng-lottie') as Element,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/assets/img/svg/category/mbo/mbo-icoon.json'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;
        console.log(scrollPercent);

        const frame = Math.min(Math.max(scrollPercent * 60 * 8, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }
}
